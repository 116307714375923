<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th style="width: 200px">Tên khách hàng <span style="color: #ff0000">*</span></th>
                    <th style="width: 170px">Số điện thoại <span style="color: #ff0000">*</span></th>
                    <th style="width: 200px">Nguồn</th>
                    <th style="width: 100px">Năm sinh</th>
                    <th style="width: 250px">Phân loại <span style="color: #ff0000">*</span></th>
                    <th style="width: 200px">Khóa</th>
                    <th style="width: 200px">Coach phụ trách</th>
                    <th style="width: 40px"></th>
                </tr>
            </thead>
            <tbody>
                <customer-row v-for="(customer, index) in listCustomer" :key="index" :customerData="customer" :customerIndex="index" />
            </tbody>
        </table>

        <div class="d-flex justify-content-end align-items-center mt-4">
            <button class="btn btn-secondary" @click="addCustomer">
                <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
                </span>
                Thêm một khách hàng khác</button>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('customerReceives');
export default {
    name: 'FormCreate',
    components: {
        'customer-row': () => import('./CustomerReceiveRow.vue'),
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['listCustomer']),
    },
    methods: {
        ...mapActions(['addCustomer'])
    },
}
</script>